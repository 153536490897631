@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.terms-section {
  animation: fadeIn 1.2s ease-in-out;
}

.hero-section__content, .terms-section__infoterms-section__info{
  animation: fadeInUp 1s ease-out;
  animation-delay: 0.3s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.terms-section {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(249, 247, 254, 1)
  );
  padding-top: 130px;
  padding-bottom: 75px;
  font-family: "Poppins", sans-serif !important;
}

.terms-section__content {
  max-width: 1280px;
  padding: 0 3rem;
  line-height: 1.8;
  margin: auto;
  gap: 20px;
}

.terms-section__title {
  line-height: 58px;
  font-size: 42px;
  text-align: center;
  color: #5a5a5a;
  font-weight: bold;
}
.terms-section__info h3 {
  font-size: 24px;
  font-weight: bold;
  color: #5a5a5a;
  margin-bottom: 20px;
}

p {
  font-family: "Montserrat", sans-serif; /* Cambia la fuente */
  font-size: 17px; /* Aumenta el tamaño */
  line-height: 1.5; /* Mejora la legibilidad */
  color: #5a5a5a; /* Hace que el color sea más oscuro */
  font-weight: 500; /* Más negrita */
  text-align: justify;
}


h3{
  font-size: 20px;
  font-weight: bold;
}

h4{
  font-size: 17px;
  font-weight: bold;
  color: #5a5a5a; /* Hace que el color sea más oscuro */
}

.terms-section__info strong {
  font-weight: 700;
  color: #5a5a5a;
}