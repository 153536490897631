.testimonials {
  background-image: linear-gradient(
    to ,
    rgba(255, 255, 255, 1),
    rgba(249, 247, 254, 1)
  );
}

.title__landing{
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  margin: 1.5rem 0;
}

.title__landing.appear{
  opacity: 1;
  transform: translateY(0);
}

.testimonials__wrapper {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  max-width: 1200px;
  margin: auto;
  padding: 3rem 0;
}

.testimonials__wrapper.appear{
  opacity: 1;
  transform: translateY(0);
}

.title__landing {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  margin: 1.5rem 0;
}

.title__landing.appear{
  opacity: 1;
  transform: translateY(0);
}

.testimonials__title {
  font-size: 42px;
  line-height: 58px;
  text-align: center;
  padding: 1.5rem 0;
  margin: 1rem 0;
}

.testimonials__cards {
  display: grid;
  padding: 0 50px;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.testimonials__hero {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  width: 80%;
}

.testimonials__hero.appear{
  opacity: 1;
  transform: translateY(0);
}

.testimonials__hero img {
  margin: auto;
  width: inherit;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.testimonials__hero.appear img{
  opacity: 1;
  transform: translateY(0);
}

.testimonials__box {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;

  background: linear-gradient(135deg, #ffffff, #f8f9ff);
  box-shadow: 0 10px 20px rgba(42, 38, 255, 0.15);
  width: auto;
  height: auto;
  padding: 1.5rem;
  border-radius: 0px 30px 0px 30px;
  transition: 
    transform 0.4s cubic-bezier(0.25, 1, 0.5, 1),
    box-shadow 0.4s ease-in-out,
    background 0.5s ease-in-out;
}

.testimonial_tittle{
  font-size: 23px;
  text-align: center;
}

.testimonials__box.appear{
  opacity: 1;
  transform: translateY(0);
}


.testimonials__box:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 20px 40px rgba(42, 38, 255, 0.3);
  background: linear-gradient(135deg, #eef0ff, #dde1ff);
}

.testimonials__box-wrapper {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}


.testimonials__box-avatar{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.testimonials__box-avatar img{
  border-radius: 100%;
}

.testimonials__box-title-video {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 0 1rem 0;
}

.testimonials__box-link-video {
  text-decoration: none;
  border: 2px solid #3a38b1;
  padding: 10px 55px;
  border-radius: 5px;
  font-size: 14px;
  color: #3a38b1;
  font-weight: 500;
  background-color: transparent;
  transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.testimonials__box-link-video:hover{
  background-color: #3a38b1;
  color: #ffffff;
  box-shadow: 0 5px 15px rgba(58, 56, 177, 0.3);
  transform: scale(1.07);
  border-color: #3a38b1;
}

.testimonials__paragraph {
  font-size: 0.85rem;
  line-height: 1.25rem;
  margin: 0 0 1rem 0;
}

@media (max-width: 980px) {
  .testimonials__hero {
    display: none;
  }

  .testimonials__cards {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    margin: 0 20px;
    gap: 2.5rem;
  }

  /* .testimonials__box-wrapper {
    width: 80%;
    margin: auto;
    padding: 0;
  } */

  .testimonials__wrapper-comparative {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 50px;
  }

  .testimonials__wrapper {
    padding: 20px 0;
  }
}
