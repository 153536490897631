.modal-video {
  position: fixed;
  top: 0;
  z-index: 99999;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-video__close {
  border: none;
  padding: 8px;
  background-color: #2a26ff;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-video__wrapper {
  position: relative;
}

.modal-video__iframe {
  width: 560px;
  height: 315px;
}

@media (max-width: 980px) {
  .modal-video__iframe {
    width: 100%;
    height: auto;
  }
}
