@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Aplicar la animación a los elementos */
.hero-section {
  animation: fadeIn 1.2s ease-in-out;
}

.hero-section__content,
.hero-section__counter,
.testimonials__wrapper-comparative {
  animation: fadeInUp 1s ease-out;
  animation-delay: 0.3s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.hero-section__title,
.hero-section__description,
.hero-section__button {
  animation: fadeInUp 0.8s ease-in-out;
  animation-delay: 0.5s;
  opacity: 0;
  animation-fill-mode: forwards;
}


.hero-section {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(249, 247, 254, 1)
  );
  padding-top: 150px;
  padding-bottom: 75px;
}

.hero-section__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  padding: 0 3rem;
  margin: auto;
  gap: 20px;
}

.hero-section__title {
  line-height: 58px;
  font-size: 42px;
}

.hero-section__description {
  color: #8d8d8d;
  font-size: 20px;
  line-height: 30px;
  max-width: 620px;
}

.hero-section__button {
  background-color: #ffffff;
  border: 2px solid #2a26ff;
  border-radius: 12px;
  color: #2a26ff;
  font-weight: 700;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
  border-color 0.3s ease-in-out;
}

.hero-section__button:hover{
  background-color: #2a26ff;
  color: white;
  border: white;
}

.benefits__section {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.hero-section__counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  
  border-radius: 20px;
  padding: 20px;
  margin-top: 80px;

  background: linear-gradient(135deg, #ffffff, #f8f9ff);
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.hero-section__counter:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(42, 38, 255, 0.2);
}

.hero-section__counter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.hero-section__counter-title {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
}

.hero-section__counter-description {
  margin: 0;
  font-size: 20px;
  color: #8d8d8d;
}

.testimonials__wrapper-comparative {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.testimonials__comparative {
  width: 320px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.testimonials__comparative:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
}

.testimonials__title-comparative {
  font-size: 22px;
  font-weight: 700;
  padding: 20px;
  text-align: center;
  color: #ffffff;
}

.testimonials__comparative:first-child .testimonials__title-comparative {
  background: linear-gradient(135deg, #8e8edc, #2a26ff);
}

.testimonials__comparative--other .testimonials__title-comparative {
  background: linear-gradient(135deg, #ff6161, #ff9a9a);
}

.testimonials__title-list {
  list-style: none;
  padding: 20px;
}

.testimonials__title-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.3s ease;
}

.testimonials__title-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.testimonials__comparative:first-child .testimonials__title-item svg {
  fill: #8e8edc;
}

.testimonials__comparative--other .testimonials__title-item svg {
  fill: #ff6161;
}


.hero-section__image {
  height: 420px;
  width: 450px;
}

@media (max-width: 768px) {
  .header__top {
    flex-direction: column;
  }

  .hero-section__title {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }

  .hero-section__content {
    flex-direction: column;
  }

  .hero-section__image {
    height: auto;
  }

  .hero-section__description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .hero-section__wrapper-text {
    text-align: center;
  }

  .hero-section__counter {
    display: block;

    gap: 20px;
  }

  .hero-section__wrapper-images {
    text-align: center;
  }
}

@media (max-width: 1086px) {
  .hero-section__counter-title {
    font-size: 30px;
  }

  .hero-section__counter-description {
    font-size: 18px;
    text-align: center;
  }

  .hero-section {
    padding-bottom: 40px;
  }

  .hero-section__button {
    display: inline-block;
    margin: 10px 0;
  }
}
