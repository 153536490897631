.header {
  background-color: #ffff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  box-shadow: 0px 4px 6px 0px rgba(110, 98, 145, 0.06);
  /* font-family: 'couture-bold', sans-serif; */
}

.header__navbar {
  align-items: center;
  display: flex;
  gap: 25px;
}

.header__navbar-link {
  background-color: #fff;
  border: 2px solid #4c17d3;
  border-radius: 12px;
  color: #4c17d3;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.header__navbar-link:hover {
  border: transparent;
  transform: scale(1.05); /* Aumenta un poco el tamaño */
  box-shadow: 0 5px 15px rgba(42, 38, 255, 0.3);
}

.header__bg--blue {
  background: linear-gradient(90deg, #1a1a99, #2724cd);
  color: #fff;
}

.header__bg--blue:hover{
  background-color: #2a26ff;
}

.header__sections-list {
  cursor: pointer;
  display: flex;
  gap: 1.5rem;
}

.header__sections-list li {
  list-style: none;
}

.header__sections-list li a {
  text-decoration: none;
  color: #000;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.header__wrapper-logo {
  align-items: center;
  display: flex;
}

.header__top {
  padding: 12px 0;
  display: flex;
  justify-content: end;
}

.header__top ul {
  margin: 0;
  list-style: none;
}

.header__top-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header__top-list {
  display: flex;
  justify-content: end;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header__top-link {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.header__top-link:hover {
  color: black;
}

.header__top-link::before {
  content: '';
  width: 0;
  height: 1px;
  background: #fff;
  right: 0;
  left: auto;
  bottom: -3px;
  position: absolute;
  transition: width 0.3s linear;
}

.header__top-link:hover::before {
  width: 100%;
  right: auto;
  left: 0;
}

.header__logo {
  margin: 0;
}

.menu-toggle {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  display: none; /* Oculto por defecto */
  position: relative;
  width: 30px;
  height: 30px;
}

.menu-icon {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #2a26ff;
  transition: all 0.3s ease;
  left: 0;
}

.menu-icon::before,
.menu-icon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #2a26ff;
  transition: all 0.3s ease;
  left: 0;
}

.menu-icon::before {
  top: -10px;
}

.menu-icon::after {
  top: 10px;
}

.menu-toggle.open .menu-icon {
  background-color: transparent;
}

.menu-toggle.open .menu-icon::before {
  transform: rotate(45deg);
  top: 0;
}

.menu-toggle.open .menu-icon::after {
  transform: rotate(-45deg);
  top: 0;
}

.header__logo-img {
  height: 40px;
}

@media (max-width: 1100px) {
  .header__top {
    justify-content: center;
  }

  .header__top-list {
    justify-content: center;
  }

  .header__wrapper {
    padding: 1rem;
  }

  .header__sections {
    display: none;
    position: absolute;
    bottom: -223px;
    left: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  }

  .header__sections--open {
    display: flex;
  }

  .header__sections-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .menu-toggle {
    display: block; /* Mostrar el botón de menú en pantallas pequeñas */
  }

  .header__logo-img {
    height: 24px;
  }

  .header__navbar-link {
    padding: 10px;
    font-size: 14px;
  }
}
