
  .WrapperContainer {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: auto;
    height: 100vh;

    /* display */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem; 
  }

  .WrapperBoxSmall {
    padding: 2.5rem 3rem;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .text__title {
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-align: center;
    max-width: 55%;
    margin: 20px auto;
  }

  .logoImage {
    width: 90px;
    height: auto;
  }

  .imagePlay {
    width: 140px;
  }

  .alilandingImage {
    height: auto;
    width: 120%;
    margin-left: -120px;
  }

  .content__signin{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .play__store{
    transition: transform 0.4s ease-out;
  }

  .play__store:hover{
    transform: scale(1.1);
  }

  /* Menor a 1204 */
  @media (max-width: 1204px) {
    .text__title {
      font-size: 16px;
    }

    .alilandingImage{
      margin-left: -90px;
    }
  }

  /* Menor a 1110 */
  @media (max-width: 1110px) {
    .imageContainer {
      width: 50%;
    }

    .text__title {
      font-size: 15px;
      max-width: 65%;
    }
  }

  /* Tablet */
  @media (max-width: 975px) {
    .alilandingImage {
      margin-left: -70px;
    }
  }

  /* Celulares */
  @media (max-width: 768px) {
    .WrapperContainer {
      flex-direction: column;
    }

    .imageContainer{
      display: none;
    }

    .text__title {
      font-size: 12px;
      max-width: 100%;
    }

    .WrapperBoxSmall{
      padding: 1rem 2rem;
      margin-top: 8rem;
      border-radius: 16px;
      background-color: #ffffff;
      margin-bottom: 1rem;
    }
  }
