/* PerflayIntegration.css */
.perflay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.perflay-card {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1.5rem;
  background: linear-gradient(135deg, #f0f4ff 0%, #f5f0ff 100%);
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  text-align: center;
}

.logo-container {
  margin-bottom: 1.5rem;
  background: white;
  padding: 0.6rem;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.perflay-logo {
  width: 110px;
  height: auto;
}

.perflay-title {
  font-size: 1.35rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 2rem;
  line-height: 1.4;
}

.video-button {
  position: relative;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(to right, #8a2be2, #4c6ef5);
  color: white;
  font-weight: 600;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(138, 43, 226, 0.3);
}

.video-button:hover {
  transform: translateY(-3px) scale(1.03);
  box-shadow: 0 8px 20px rgba(138, 43, 226, 0.4);
}

.video-button:active {
  transform: translateY(1px);
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.play-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 5px;
}

.create-account-link {
  font-weight: bold;
  font-size: 20px;
  color: #008060;
  text-decoration: none;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
}

.create-account-link:hover {
  transform: translateY(-3px) scale(1.00);
  color: #006048;
  font-weight: bold;
}

.perflay-subtext {
  color: #666;
  font-size: 0.9rem;
  text-align: center;
}

.token-section {
  width: 100%;
}

.token-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 0.5rem;
  text-align: left;
}

.token-input-container {
  position: relative;
  width: 100%;
}

.token-input {
  width: 100%;
  padding: 0.75rem 1rem;
  padding-right: 2.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-family: monospace;
  font-size: 0.875rem;
  color: #444;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
}

.token-input:focus {
  outline: none;
  border-color: #9046cf;
  box-shadow: 0 0 0 3px rgba(144, 70, 207, 0.2);
}

.copy-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  transition: color 0.2s ease;
}

.copy-button:hover {
  color: #9046cf;
}

.copied-tooltip {
  position: absolute;
  top: -35px;
  right: 0;
  background-color: #333;
  color: white;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
}

.copied-tooltip:after {
  content: '';
  position: absolute;
  bottom: -5px;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
}

@media (max-width: 640px) {
  .perflay-card {
    padding: 2rem 1rem;
  }
  
  .perflay-title {
    font-size: 1.2rem;
  }
}