/* Define la animación cíclica */
@keyframes shake-cycle {
  0%,
  25% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateX(5px);
  }
  30% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

/* Clase para el botón */
.shake-button {
  color: #2a26ff;
  background: white;
  border-radius: 30px;
  height: 36px;
  font-weight: bold;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 260px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transición suave */

  animation: shake-cycle 3s ease-in-out infinite;
}

.shake-button:hover {
  background-color: #a8adff !important;
  color: #2a26ff !important;
}
