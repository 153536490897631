.features {
  background-image: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgba(249, 247, 254, 1)
  );
  padding: 1.5rem 0;
  padding-bottom: 6rem;
}

.title__landing {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  margin: 1.5rem 0;
}

.subtitle__landing {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  color: #8d8d8d;
  font-size: 1.25rem;
  line-height: 1.85rem;
  text-align: center;
}

.title__landing.appear{
  opacity: 1;
  transform: translateY(0);
}

.subtitle__landing.appear{
  opacity: 1;
  transform: translateY(0);
}

.features__opacy{
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.features__opacy.appear{
  opacity: 1;
  transform: translateY(0);
}

.features__list {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  margin: 3rem auto 0 auto;
  padding: 0 50px;
  align-items: stretch;
}

.features__list.appear{
  opacity: 1;
  transform: translateY(0);
}

.features__subtitle {
  margin: 1rem 0;
}

.features__paragraph {
  color: #8d8d8d;
  font-size: 16px;
  line-height: 25px;
}

.features__description {
  max-width: 50%;
  margin: auto;
}

.features__wrapper {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.features__wrapper.appear{
  opacity: 1;
  transform: translateY(0);
}

.features__wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.features__icon {
  background: radial-gradient(circle, #d9e6fb 30%, #2a26ff 80%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}

.features__icon svg {
  width: 40px;
  height: 40px;
  fill: #003a3a;
}

@media (max-width: 768px) {
  .title__landing {
    font-size: 30px;
    line-height: 36px;
    margin: 1.5rem 10px;
  }

  .features__description {
    font-size: 16px;
    line-height: 24px;
    max-width: 80%;
  }

  .features-list{
    height: 100%;
  }

  .mySwiper {
    width: 240px;
    height: 320px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
}
