/* ShopifyIntegration.css */
.shopify-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  width: 100%;
}

.shopify-card {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 1.5rem;
  background: linear-gradient(135deg, #f5fff7 0%, #f0f8ff 100%);
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  text-align: center;
}

.logo-wrapper {
  margin-bottom: 1.5rem;
  background: white;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopify-title {
  font-size: 1.35rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
  line-height: 1.4;
  border-bottom: #333;
}

.shopify-subtext {
  color: #666;
  font-size: 0.95rem;
  margin-bottom: 2rem;
  text-align: center;
}

.video-buttons {
  position: relative;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(to right, #007f5f, #00b374);
  color: white;
  font-weight: 600;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 128, 96, 0.3);
}

.video-buttons:hover {
  transform: translateY(-3px) scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 128, 96, 0.4);
}

.video-buttons:active {
  transform: translateY(1px);
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.play-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

@media (max-width: 640px) {
  .shopify-card {
    padding: 2rem 1rem;
  }
  
  .shopify-title {
    font-size: 1.2rem;
  }
}