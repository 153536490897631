.footer {
  background: linear-gradient(90deg, #1a1a99, #2724cd);
  color: #fff;
  padding-bottom: 20px;
}

.footer-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.footer-wrapper-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.footer-text {

  font-size: 1.8rem;
  font-weight: bold;
}

.footer-wrapper-social-network {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
}

.footer-social-network {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.footer-social-network:hover {
  transform: scale(1.1);
  background-color: #f8f9ff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.footer-wrapper-link {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-contain-social {
  display: flex;
  flex-direction: column; /* Poner los elementos en columna */
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.footer-wrapper-social {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.footer-social-network {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.footer-social-network:hover {
  transform: scale(1.1);
}

/* Estilos para la sección de descarga de la app */
.footer-wrapper-app {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column; /* Organiza en columna el texto y el botón */
  align-items: center;
  text-align: center;
}

.footer-app-title {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
}

.footer-social-network-app {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.footer-app-image {
  width: 200px;
  height: auto;
  transition: transform 0.4s ease-out; /* Más suave */
}

.footer-app-image:hover {
  transform: scale(1.2); /* Aumenta un 10% */
}

.footer-wrapper-link {
  display: flex;
  flex-direction: column;
}

.footer-wrapper-link h6 {
  margin: 0;
  font-size: 1rem;
}

.footer-wrapper-link ul {
  list-style: none;
  font-size: 1rem;
  line-height: 2rem;
}

.footer-logo {
  font-size: 1.5rem;
}

.footer-wrapper-link ul li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-wrapper-resources {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 1rem;
}

.footer-wrapper-resources p {
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
}

.footer-wrapper-resources ul {
  margin: 0;
  padding: 0;
}

.footer-wrapper-resources ul li a{
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}
.footer-wrapper-resources ul li a:hover {
  color: black;
}

.footer-wrapper-admin{
  display: flex;
  gap: 10px;
  align-items: center;
}



@media (max-width: 1100px) {
  .footer-wrapper-logo {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 25px 0px;
  }

  .footer-contain-social{
    gap: 0;
    padding: 0;
  }

  .footer-text {
    font-size: 20px;
    margin: 20px 0;
  }

  .footer-wrapper-social{
    padding-bottom: 30px;
  }

  .footer-wrapper-social-network {
    flex-direction: column-reverse;
  }

  .footer-wrapper-resources {
    display: block;
    padding-top: 0;
  }

  .footer-wrapper-resources p {
    text-align: center;
  }

  .footer-wrapper-resources ul li {
    justify-content: center;
  }
}
