.ProductCard {
  width: 100%;
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-bottom: 30px;
}

.container{
  margin: 0 10px 0 10px;
}

.proveedor_des{
  font-size: 14px;
  font-weight: bold;
}

.ProductDescription {
  font-size: 16px;
  font-weight: bold;
}

.ProductCardFooter{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProductCard button {
  background-color: #3a37f3 !important;
  font-size: 14px;
}

@media (max-width: 1300px) {
  .ProductDescription {
    font-size: 12px;
    font-weight: bold;
  }
  .proveedor_des{
    font-size: 12px;
  }
}