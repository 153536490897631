.questions {
  background-image: linear-gradient(
    to bottom,
    rgba(249, 247, 254, 1),
    rgba(255, 255, 255, 1)
  );
  padding: 50px;
}

.questions__wrapper {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  max-width: 1200px;
  margin: auto;
}

.questions__wrapper.appear{
  opacity: 1;
  transform: translateY(0);
}

.questions__title {
  font-size: 42px;
  line-height: 58px;
  text-align: center;
  padding: 1.5rem 0;
  margin: 1rem 0;
}

.questions-items {
  display: flex;
}

.questions-item {
  border-radius: 8px;
  border: 1px solid #c6c6c6;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.questions-item[open] {
  border-color: #2724cd;
  box-shadow: 0 4px 8px rgba(39, 36, 205, 0.2);
}

.questions-summary {
  font-size: 1.1rem;
  color: #2724cd;
  font-weight: 600;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  transition: background-color 0.3s ease-in;
}

.questions-summary:hover {
  background-color: rgba(39, 36, 205, 0.1);
}

.questions-summary::after {
  content: "＋";
  font-size: 1.5rem;
  color: #2724cd;
  transition: transform 0.3s ease-in-out;
}

.questions-item[open] .questions-summary::after {
  content: "−";
  transform: rotate(180deg);
}

.questions-text {
  line-height: 1.5rem;
  padding: 0 1rem;
  color: #000000;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transform: translateY(-1px);
  transition: opacity 0.5s ease, max-height 0.5s ease-in-out, transform 0.3s ease-in-out;
}

.questions-item[open] .questions-text {
  opacity: 1;
  max-height: 500px;
  transform: translateY(0);
}

.questions-wrapper-item {
  flex-basis: 50%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (max-width: 980px) {
  .questions-items {
    flex-direction: column;
    gap: 2rem;
  }

  .questions__wrapper {
    padding: 4px 0;
  }

  .title__landing{
    padding: 10px;
  }
}
