.forget-pass{
  color: #4a47ff;
}

.forget-pass:hover{
  color: #6b68f7 !important;
}

.button__container{
  border: #4a47ff solid 1px !important;
}

.button__container:hover{
  border: #4a47ff solid 1px;
}

.button__login{
  border: #4a47ff solid 1px;
  background: #4a47ff;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button__account{
  color: #4a47ff;
  border: #4a47ff solid 1px;
  border: #4a47ff solid 1px !important;
}

.button__account:hover{
  color: #4a47ff !important;
  border: #4a47ff solid 1px !important;
  background-color: #f0eff9 !important;
  opacity: 1 !important;
}

.button__login:hover{
  opacity: 1 !important;
  background-color: #6b68f7 !important;
  color: white !important;
  border: #4a47ff !important;
}

.accept-terms-container {
  margin-left: 0;
}