.title {
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.subtitle {
  display: block;
  text-align: center;
  margin-bottom: 20px;
  color: #666;
}