.benefits {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(249, 247, 254, 1)
  );
  padding: 10px;
}

.benefits__wrapper {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  max-width: 1200px;
  margin: auto;
  padding: 3rem 0;
}

.benefits__wrapper.appear{
  opacity: 1;
  transform: translateY(0);
}

.title__landing{
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  margin: 1.5rem 0;
}

.title__landing.appear{
  opacity: 1;
  transform: translateY(0);
}

.benefits__title {
  font-size: 42px;
  line-height: 58px;
  text-align: center;
  padding: 1.5rem 0;
  margin: 1rem 0;
}

.col-lg-6 {
  max-width: 50%;
}

.col-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.benefits__image {
  text-align: center;
}

.benefits__section:nth-child(even) {
  flex-direction: row-reverse;
}

.benefits__subtitle {
  font-size: 2rem;
  color: #2a26ff;
}

.benefits__paragraph {
  font-size: 1rem;
  line-height: 25px;
}

.benefits__wrapper-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-top: 60px;
  padding: 0 50px;
  align-items: stretch;
}

.benefits__item {
  margin: auto;
  padding: 32px;
  border-radius: 10px;
  box-shadow: 0px 16px 24px 0px rgba(173, 173, 173, 0.5);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.benefits__item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(42, 38, 255, 0.2);
}

.benefits__item-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  margin: 0;
}

.benefits__item-paragraph {
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  margin: 0;
}

.benefits__item-button {
  color: #2a26ff;
  padding: 8px 16px;
  border-radius: 12px;
  text-decoration: none;
  border: 2px solid #2a26ff;
  background-color: #ffffff;
  cursor: pointer;
  font-weight: 700;
}

@media (max-width: 980px) {

  .benefits__section,
  .benefits__section:nth-child(even) {
    flex-direction: column;
  }

  .col-lg-6 {
    max-width: 80%;
  }

  .benefits__subtitle {
    text-align: center;
    font-size: 24px;
    margin: 20px 0;
  }

  .benefits__wrapper-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .benefits__item {
    max-width: 70%;
  }

  .benefits__wrapper {
    padding: 20px 0;
  }

  .benefits__paragraph {
    text-align: center;
  }
}
