body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html,
#root {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'couture-bold';
  src: url('./assets/fonts/couture-bld.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'couture-italic';
  src: url('./assets/fonts/couture-bldit.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.title__landing {
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  margin: 1.5rem 0;
}

.subtitle__landing {
  color: #8d8d8d;
  font-size: 1.25rem;
  line-height: 1.85rem;
  text-align: center;
}

.container__landing {
  max-width: 1200px;
  margin: auto;
}

.images__landing {
  border-radius: 20px;
}

@media (max-width: 980px) {
  .images__landing {
    max-width: 80%;
    height: auto;
  }
}

html {
  scroll-behavior: smooth;
}
